<template>
  <div class="login-page">
    <loginLayout :footerText="AUTH_TRANSLATE?.common.footer.company.label" :footerLink="AUTH_TRANSLATE?.common?.footer?.company?.btn?.register">
      <div class="content">
        <h2 class="r-title-2">{{ AUTH_TRANSLATE?.create_password?.title }}</h2>
        <div class="form-inputs">
          <InputText v-model:value.trim="user.password" :errorText="VALIDATION_TRANSLATE?.login?.password" :valid="validPass" :type="passwordInputType" :label="AUTH_TRANSLATE?.create_password.form.password">
            <ButtonShowPass v-model:type="passwordInputType"></ButtonShowPass>
          </InputText>
          <InputText v-model:value.trim="user.confirm" :errorText="VALIDATION_TRANSLATE?.login?.password" :valid="validMatch" :type="confirmInputType" :label="AUTH_TRANSLATE?.create_password.form.confirm_password">
            <ButtonShowPass v-model:type="confirmInputType"></ButtonShowPass>
          </InputText>
        </div>
        <div class="form-btns">
          <Button @click="sendPass">{{ AUTH_TRANSLATE?.create_password?.form?.btn?.save }}</Button>
          <span class="policy">
            {{ AUTH_TRANSLATE?.common?.policy?.text }}
            <a :href="AUTH_TRANSLATE?.common?.policy?.link" target="_blank" class="policy-link">
              {{ AUTH_TRANSLATE?.common?.policy?.color_text }}
            </a>
          </span>
        </div>
      </div>
    </loginLayout>
  </div>
</template>

<script>
import ButtonShowPass from "../components/parts/button-show-pass";
import Button from "../components/parts/button";
import InputText from "../components/parts/input-text";
import loginLayout from "../components/loginLayout";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    loginLayout,
    Button,
    ButtonShowPass,
    InputText
  },
  data() {
    return {
      passwordInputType: "password",
      confirmInputType: "password",
      user: {
        email: "",
        password: "",
        confirm: ""
      }
    };
  },
  computed: {
    ...mapGetters(["EMAIL_TOKEN", "AUTH_TRANSLATE", "VALIDATION_TRANSLATE"]),
    validPass() {
      return !(this.user.password.length < 8);
    },
    validMatch() {
      return !(this.user.confirm.length < 8);
    }
  },
  methods: {
    ...mapActions(["PassCreate", "SummonAlert", "AuthTranslate", "ValidationTranslate"]),
    async sendPass() {
      if (this.user.password.length > 7 && this.user.password == this.user.confirm) {
        let res = await this.PassCreate({
          password: this.user.password,
          password_confirmation: this.user.confirm
        });
        if (res == "success") {
          this.user.password = "";
          this.user.confirm = "";
          this.$router.push({ name: "Login" });
        }
      } else {
        this.SummonAlert({ message: this.AUTH_TRANSLATE?.auth.alert.failed, type: "error" });
      }
    }
  },
  created() {
    this.AuthTranslate();
    this.ValidationTranslate();
  },
  mounted() {
    if (!this.EMAIL_TOKEN) this.$router.push({ name: "Login" });
  }
};
</script>

<style lang="scss">
.login-page {
  height: 100%;
  width: 100%;
  .content {
    padding: 49px 20px 19px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    h2 {
      font-weight: 400;
      color: var(--dark-100);
      margin-bottom: 45px;
    }
  }
  .policy {
    display: block;
    color: var(--dark-40);
    font-size: 12px;
    margin-bottom: 20px;
    letter-spacing: 0.5px;

    &-link {
      color: var(--blue-main);
      text-decoration: none;
    }
  }
  .form-inputs {
    display: flex;
    margin-bottom: 35px;
    .main {
      width: 100%;
      @media (min-width: 1025px) {
        &:first-child .input-box {
          margin-right: 20px;
        }
      }
    }
    svg {
      color: var(--dark-40);
    }
    &:focus-within {
      svg {
        color: var(--blue-main);
      }
      .not-valid {
        svg {
          color: var(--error);
        }
      }
    }
  }
  .form-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    button {
      margin-right: 20px;
      margin-bottom: 20px;
    }
    button.white:hover {
      background: var(--bg-white);
      color: var(--blue-main);
    }
  }
  @media (max-width: 1024px) {
    .left-box {
      min-width: 500px;
    }
    .form-inputs {
      flex-wrap: wrap;
      .main:first-child {
        margin-bottom: 30px;
      }
    }
    .content {
      padding-top: 85px;
    }
    .message-bottom {
      bottom: 20px;
    }
  }
  @media (max-width: 764px) {
    .content {
      padding-top: 36px;
    }
    .login-layout header {
      background: var(--white-100);
      padding: 12px 0 12px 21px;
      img {
        margin: auto 0;
      }
    }
    .right-box {
      background-color: var(--bg-main);
      position: absolute;
      top: 0;
      margin-top: 72px;
      max-height: 98px;
      min-height: 98px;
    }
    .content {
      padding-right: 20px;
    }
    .main {
      min-width: 200px;
    }
    .left-box {
      min-width: 320px;
      padding-top: 170px;
    }
    .message-bottom {
      top: 674px;
      p.m-text-des {
        padding-bottom: 20px;
      }
    }
  }
  @media (max-width: 370px) {
    .form-btns {
      flex-direction: column;
      .white {
        margin-top: 20px;
      }
    }
  }
}
</style>
